import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { chargeAccount } from "../reducers/accountsReducer";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: "2px 4px",
    display: "inline-flex",
    alignItems: "center",
    verticalAlign: "top"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  input: {
    marginLeft: theme.spacing(1)
    // flex: 1,
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

export default function ChargeAccount(params) {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);

  const classes = useStyles();

  const OnCancel = event =>{
    if( params.cancel)
      params.cancel()
  }
  const OnCharge = event =>{    
    dispatch(chargeAccount(params.account_id, amount));
    if( params.cancel)
      params.cancel()
  }

  return (
    <React.Fragment>
    <Dialog
      open  
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Charge Credit Card"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        <TextField
          label="Amount"
          fullWidth 
          className={classes.textField}
          value={amount}
          onChange={e=>setAmount(e.target.value)}
        />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>OnCancel()} color="primary">
          Cancel
        </Button>
        <Button onClick={()=>OnCharge()} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
    </React.Fragment>
  );
}
