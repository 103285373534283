import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Button from '@material-ui/core/Button';

import { getAccounts, clearAccountsError } from "../reducers/accountsReducer";
import Account from "./Account";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  tableWrapper: {
    maxHeight: 440,
    overflow: "auto"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Accounts() {
  const accountsState = useSelector(state => state.accounts);
  const [isLoading, setisLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(accountsState.isError);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch(clearAccountsError());
    setOpen(false);
  };
  if (accountsState.isLoading != isLoading)
    setisLoading(accountsState.isLoading);
  if(accountsState.isError != open)
    setOpen(accountsState.isError);

  useEffect(() => {
    const fetchAccounts = async () => {
      dispatch(getAccounts());
    };
    if( accountsState.list.length === 0 && !accountsState.isLoading)
      fetchAccounts();
  }, []);

  const data = accountsState.list.map(account => {
    return { ...account, name: account.name2, city: account.city2 };
  });

  console.log(`accountsState.isError=${accountsState.isError}`)
  return (
    <React.Fragment>
      <MaterialTable
        isLoading={isLoading}
        title="Multiple Detail Panels Preview"
        columns={[
          { title: "ID", field: "id" },
          { title: "Company", field: "name" },
          { title: "City", field: "city" },
          { title: "tax_number", field: "tax_number" },
          { title: "invoice4uID", field: "invoice4uID" }
        ]}
        data={data}
        detailPanel={[
          {
            icon: "edit",
            tooltip: "Edit account",
            render: rowData => {
              return <Account rowData={rowData} />;
            }
          }
        ]}
      />
      {accountsState.isError && 
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth={'sm'}
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Error"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {accountsState.errors}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Dismiss
            </Button>
          </DialogActions>
        </Dialog>
      }
    </React.Fragment>
  );
}
