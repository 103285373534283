import React, { Fragment, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  tableWrapper: {
    maxHeight: 440,
    overflow: "auto"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  //style for font size
  resize:{
    fontSize:"1rem"
  },
  resize_small:{
    fontSize:"0.8rem"
  },
  menu: {
    width: 200
  },
  buttonField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    display: "flex"
  }
}));

export default function Options(rowData) {
  const classes = useStyles();
  const [editOptions, setEditPackages] = useState(false);

  return (
    <Fragment>
      <Paper component="form" className={classes.root}>
        <TextField
          label="Options"
          className={classes.input}
          value={rowData.packages}
          margin="dense"
          InputProps={{
            readOnly: true,
            classes: {
              input: classes.resize,
            }
          }}
          variant="filled"
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
          onClick={e => {
            e.preventDefault();
            setEditPackages(true);
            console.log("setEditPackages")
          }}
        >
          <EditIcon />
        </IconButton>
      </Paper>
      {/* {editOptions && <EditOptions shipment_id={rowData.id} setEditOptions={setEditPackages} />} */}
    </Fragment>
  );
}
