import API from "../api/accountsapi";

const defaultPayments = {
  list: [],
  isLoading: false,
  isError: false,
  refresh: true,
  last_refresh_time: new Date(),
  errors: ""
};

export const getPayments = (
  account_id,
  from_date,
  to_date
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(removePaymentsList());
      dispatch(setIsLoading());
      const payments = await API.getPayments(
        account_id,
        from_date,
        to_date
      );
      dispatch(setPayments(payments));
    } catch (err) {
      dispatch(setPaymentsError(err.message || err.error.message));
    }
  };
};
export const updatePayment = (invoice_id, action, data) => {
  return async (dispatch, getState) => {
    try {
      const invoices = await API.updateInvoice(invoice_id, action, data);
      dispatch(setUpdateInvoice(invoice_id, action, data));
    } catch (err) {
      dispatch(setPaymentsError(err.message || err.error.message));
    }
  };
};
export const setPayments = payments => {
  return {
    type: "SET_PAYMENTS_LIST",
    payments
  };
};

export const setPaymentsError = data => {
  return {
    type: "PAYMENTS_ERROR",
    data
  };
};
export const clearPaymentsError = () => {
  return {
    type: "CLEAR_PAYMENTS_ERROR"
  };
};
export const removePaymentsList = () => {
  return {
    type: "REMOVE_PAYMENTS_LIST"
  };
};
export const setIsLoading = () => {
  return {
    type: "PAYMENTS_IS_LOADING"
  };
};
export const setUpdateInvoice = (invoice_id, action, data) => {
  return {
    type: "UPDATE_INVOICE_DATA",
    invoice_id,
    action,
    data
  };
};
export default (state = defaultPayments, action) => {
  switch (action.type) {
    case "REMOVE_PAYMENTS_LIST":
      return {
        list: [],
        isLoading: false,
        isError: false,
        errors: ""
      };
    case "PAYMENTS_IS_LOADING":
      return {
        ...state,
        isLoading: true
      };
    case "SET_PAYMENTS_LIST": {
      return {
        ...state,
        list: action.payments,
        isLoading: false,
        isError: false,
        errors: ""
      };
    }
    case "UPDATE_PAYMENT_DATA": {
      return {
        list: state.list.map(invoice => {
          if (invoice.id !== action.invoice_id) return { ...invoice };
          else {
            switch (action.action) {
              case "set_payment_exists":
                return {
                  ...invoice,
                  payment_exists: action.data.is_payment_exists
                };
              default:
                return { ...invoice };
            }
          }
        }),
        isLoading: false,
        isError: false,
        errors: ""
      };
    }
    case "PAYMENTS_ERROR": {
      return {
        ...state,
        isLoading: false,
        isError: true,
        errors: action.data
      };
    }
    case "CLEAR_PAYMENTS_ERROR": {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: ""
      };
    }
    default:
      return state;
  }
};
