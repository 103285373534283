import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from '@date-io/date-fns';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

import Container from "@material-ui/core/Container";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { updateShipment} from "../reducers/activeShipmentsReducer"

const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));

export default (props)=> {
 // The first commit of Material-UI
  const [pickup_date, setPickupDate] = useState(props.pickup_date)
  const [pickup_date_to, setPickupDateTo] = useState(props.pickup_date_to)
  const [pickup_instructions, setPickupInstructions] = useState(props.pickup_instructions)

  const classes = useStyles();
  const proposalState = useSelector(state => state.proposalDetails);
  const dispatch = useDispatch();

  let timeFrom = parseISO(pickup_date, "yyyy-MM-dd HH:mm", new Date());
  let timeTo = parseISO(pickup_date_to, "yyyy-MM-dd HH:mm", new Date());
  console.log(pickup_date, timeFrom)

  // useEffect((date)=>{
  //   dispatch(updateShipment(props.id, "set_pickup_date", {date}))}, []);


  if( timeFrom.getMinutes()> 30) timeFrom.setMinutes(30);
  if( timeFrom.getMinutes()< 30) timeFrom.setMinutes(0);
  if( timeTo.getMinutes()> 30) timeTo.setMinutes(30);
  if( timeTo.getMinutes()< 30) timeTo.setMinutes(0);

const handleDateChange = date => {
  const dt = new Date(date);
  setPickupDate(format(dt, "yyyy-MM-dd HH:mm"));
};
const handleTimeFromChange = date => {
  const dt = new Date(date);
  setPickupDate(format(dt, "yyyy-MM-dd HH:mm"));
};
const handleTimeToChange = date => {
  const dt = new Date(date);
  setPickupDateTo(format(dt, "yyyy-MM-dd HH:mm"));
};
const handleSave = ()=>{
  dispatch(updateShipment(props.id, "set_pickup_date", {pickup_date, pickup_date_to, instructions: pickup_instructions}));
}
const handlePickup = ()=>{
  dispatch(updateShipment(props.id, "generate_pickup", {}));
}
return (
   <Container>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="Pickup goods on date"
          disablePast 
          format="MM/dd/yyyy"
          value={parseISO(pickup_date, "yyyy-MM-dd HH:mm", new Date())}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardTimePicker
          margin="normal"
          id="time-picker_to"
          label="From"
          value={timeFrom}
          onChange={handleTimeFromChange}
          KeyboardButtonProps={{
            'aria-label': 'change time from',
          }}
        />
        <KeyboardTimePicker
          margin="normal"
          id="time-picker_from"
          label="Until "
          value={timeTo}
          onChange={handleTimeToChange}
          KeyboardButtonProps={{
            'aria-label': 'change time until',
          }}
        />
        <TextField
          id="instructions"
          label="Pickup Instructions"
          className={classes.textField}
          value={pickup_instructions}
          onChange={e => setPickupInstructions(e.target.value)}
          helperText={`optional (${pickup_instructions.length}/35)`}
          margin="normal"
          inputProps={{ maxLength: 35 }}
        />
      </MuiPickersUtilsProvider>
      <Button variant="contained" color="primary" onClick={(e)=>handleSave()}>Save</Button>
      <Button variant="contained" color="primary" onClick={(e)=>handlePickup()}>Pickup</Button>
    </Container>
    )
}
