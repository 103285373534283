import * as redux from "redux";
import thunk from "redux-thunk";
import LogRocket from "logrocket";

import accountsReducer from "./accountsReducer";
import activeShipmentsReducer from "./activeShipmentsReducer";
import shipmentsReducer from "./shipmentsReducer";
import invoicesReducer from "./invoicesReducer";
import paymentsReducer from "./paymentsReducer";
import uidsReducer from "./uidsRedcuer"

// Be sure to ONLY add this middleware in development!
let middleware =
  process.env.NODE_ENV !== "production"
    ? [require("redux-immutable-state-invariant").default(), thunk]
    : [thunk];
// middleware = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || redux.compose;

export let configure = (initialState = {}) => {
  let reducer = redux.combineReducers({
    accounts: accountsReducer,
    activeShipments: activeShipmentsReducer,
    shipments: shipmentsReducer,
    uids: uidsReducer,
    invoices: invoicesReducer,
    payments: paymentsReducer,
  });

  let store = redux.createStore(
    reducer,
    initialState,
    redux.compose(
      redux.applyMiddleware(...middleware, LogRocket.reduxMiddleware(), thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ 
      // && process.env.NODE_ENV !== "production"
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
    )
  );

  return store;
};
