import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import StarBorder from "@material-ui/icons/StarBorder";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { connectableObservableDescriptor } from "../../../../AppData/Local/Microsoft/TypeScript/3.6/node_modules/rxjs/internal/observable/ConnectableObservable";

import Accounts from "../src/components/Accounts";
import ActiveShipments from "./components/ActiveShipments";
import AllShipments from "../src/components/AllShipments";
import CarrierInvoices from "../src/components/CarrierInvoices";
import {
  getActiveShipments,
  startRefresh,
  stopRefresh
} from "./reducers/activeShipmentsReducer";
import { getShipments } from "./reducers/shipmentsReducer";
import { getAccounts } from "./reducers/accountsReducer";
import { removeInvoicesList, getInvoices } from "./reducers/invoicesReducer";
import { getPayments,removePaymentsList } from "./reducers/paymentsReducer";

const carriersList = ["TNT", "DHL", "UPS", "UPS_TAX", "TNT_TAX", "FEDEX"];
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    // [theme.breakpoints.down("md")]: {
    //   width: drawerWidth,
    //   flexShrink: 0
    // }
  },
  appBar: {
    [theme.breakpoints.up("xl")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    // [theme.breakpoints.up("sm")]: {
    //   display: "none"
    // }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    width: "100%",
    padding: theme.spacing(1),
    // padding: theme.spacing(1)
  }
}));

function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [accountsOpen, setAccountsOpen] = React.useState(false);
  const [activeShipmentsOpen, setActiveShipmentsOpen] = React.useState(true);
  const [shipmentsOpen, setShipmentsOpen] = React.useState(false);
  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const [carrierInvoicesOpen, setCarrierInvoiceOpen] = useState(false);
  const [selectedCarrierIndex, setSelectedCarrierIndex] = useState(-1);
  const [invoiced, setInvoiced] = useState("Not Invoiced")
  const [noReference, setNoReference] = useState(false)
  const [selectedOption, setSelectedOption] = useState("Active Shipments")
  const [paymentsOpen, setPaymentsOpen] = useState(false);

  const activeShipmentsState = useSelector(state => state.activeShipments);
  const shipmentsState = useSelector(state => state.shipments);
  const accountsState = useSelector(state => state.accounts);
  const dispatch = useDispatch();

  const fetchActiveShipments = async () => {
    dispatch(getActiveShipments());
    dispatch(startRefresh(60000));
  };
  useEffect(() => {
    if (activeShipmentsState.list.length === 0) fetchActiveShipments();
  }, []);

  const fetchShipments = async () => {
    // dispatch(getShipments());
  };
  useEffect(() => {
    if (shipmentsState.list.length === 0) fetchShipments();
  }, []);

  useEffect(() => {
    const fetchAccounts = async () => {
      dispatch(getAccounts());
    };
    if (accountsState.list.length === 0) fetchAccounts();
  }, []);

  const onInvoiceClick = text => {
    setInvoiceOpen(!invoiceOpen);
  };

  const handleNoReference = (carrier_name, invoiced, noReference)=>{
    dispatch(getInvoices(carrier_name, invoiced, noReference));
  }
  const onSelectCarrier = (carrier_name, index) => {
    setAccountsOpen(false);
    setActiveShipmentsOpen(false);
    setShipmentsOpen(false);
    setSelectedOption("");
    dispatch(removeInvoicesList());

    console.log(carrier_name);
    switch (carrier_name) {
      case "TNT":
      case "TNT_TAX":
      case "DHL":
      case "UPS":
      case "FEDEX":
      case "UPS_TAX":
        // dispatch(getInvoices(carrier_name, false));
        setCarrierInvoiceOpen(true);
        setSelectedCarrierIndex(index);
        dispatch(getInvoices(carrier_name, invoiced, noReference));
        break;
      default:
        break;
    }
  };
  const onMenuClick = text => {
    setSelectedOption(text);
    setSelectedCarrierIndex(-1);
    switch (text) {
      case "Accounts":
        setAccountsOpen(true);
        setActiveShipmentsOpen(false);
        setShipmentsOpen(false);
        setInvoiceOpen(false);
        setCarrierInvoiceOpen(false)
        break;
      case "Active Shipments":
        setActiveShipmentsOpen(true);
        setShipmentsOpen(false);
        setAccountsOpen(false);
        setInvoiceOpen(false);
        setCarrierInvoiceOpen(false)
        break;
      case "All Shipments":
        setShipmentsOpen(true);
        setAccountsOpen(false);
        setActiveShipmentsOpen(false);
        setInvoiceOpen(false);
        setCarrierInvoiceOpen(false)
        break;
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onPayments = ()=>{
    setPaymentsOpen(!paymentsOpen);
    dispatch(removePaymentsList());

    dispatch(getPayments(0, "", ""));
  }

  const drawer = (
    <div>      <div className={classes.toolbar} />
      <Divider />
      <List>
        {["Accounts", "Active Shipments", "All Shipments"].map(
          (text, index) => (
            <ListItem button key={text} onClick={() => onMenuClick(text)} selected={selectedOption == text}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          )
        )}
      </List>
      <Divider />
      <List>
        {["Invoices"].map((text, index) => (
          <ListItem button key={text} onClick={() => onInvoiceClick(text)}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
            {invoiceOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        ))}
        {carriersList.map((text, index) => (
          <Collapse key={index} in={invoiceOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                selected={selectedCarrierIndex == index}
                onClick={() => onSelectCarrier(text, index)}
              >
                  {text}
                <ListItemText />
              </ListItem>
            </List>
          </Collapse>
        ))}
      </List>
      <Divider />
      <List>
        {["Payments", "Trash", "Spam"].map((text, index) => (
          <ListItem button key={text} onClick={() => onPayments(text)} selected={selectedOption == text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Responsive drawer
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        {/* <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden> */}
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {accountsOpen && <Accounts />}
        {activeShipmentsOpen && <ActiveShipments />}
        {shipmentsOpen && <AllShipments />}
        {carrierInvoicesOpen && <CarrierInvoices carrier_name={carriersList[selectedCarrierIndex]} setInvoiced={setInvoiced} setNoReference={handleNoReference}/>}
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(
    typeof Element === "undefined" ? Object : Element
  )
};

export default ResponsiveDrawer;
