import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";

import {
  getActiveShipments,
  startRefresh,
  stopRefresh,
  updateShipment,
  resetActiveShipmentsError
} from "../reducers/activeShipmentsReducer";
import Shipment from "./Shipment";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  tableWrapper: {
    maxHeight: 440,
    overflow: "auto"
  }
});

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const REFRESH_TIMEOUT = 120000;

export default function ActiveShipments() {
  const [isLoading, setisLoading] = React.useState(false);

  const dispatch = useDispatch();
  const activeShipmentsState = useSelector(state => state.activeShipments);
  if (activeShipmentsState.isLoading != isLoading)
    setisLoading(activeShipmentsState.isLoading);
  const onManuallyRefresh = () => {
    dispatch(getActiveShipments());
    dispatch(startRefresh(REFRESH_TIMEOUT));
  };
  const fetchActiveShipments = async () => {
    dispatch(getActiveShipments());
    dispatch(startRefresh(REFRESH_TIMEOUT));
  };
  useEffect(() => {
    if (activeShipmentsState.list.length === 0 && activeShipmentsState.isLoading === false)
      fetchActiveShipments();
  }, []);
  // dispatch(startRefresh(15000))
  // useInterval(
  //   () => {
  //     // Your custom logic here
  //     fetchActiveShipments();
  //   },
  //   shouldRefresh ? 10000 : null
  // );

  const handleCloseAlert = () => {
    dispatch(resetActiveShipmentsError());
  };

  const data = activeShipmentsState.list.map(shipment => {
    return { ...shipment, customer: shipment.name2 };
  });

  const togglePanelAndStopRefresh = (togglePanel, setShouldRefresh) => {
    dispatch(stopRefresh());
    togglePanel();
  };
  return (
    <React.Fragment>
      <MaterialTable
        options={{
          pageSizeOptions: [5, 10, 20, 50],
          emptyRowsWhenPaging: false,
          // paginationType: "stepped",
          grouping: true
        }}
        isLoading={isLoading}
        title="Active Shipments"
        columns={[
          { title: "Number", field: "number" },
          { title: "Customer", field: "name2" },
          { title: "Acc.", field: "account" },
          { title: "Tax", field: "tax_number" },
          { title: "Status", field: "status" },
          { title: "Message", field: "message" },
          { title: "Location", field: "last_location" },
          { title: "eventTime", field: "eventTime" },
          { title: "Tracking", field: "tracking_code" },
          { title: "Diff", field: "diff" },
          { title: "Country", field: "source_country_code" },
          { title: "Target", field: "target_country_code" },
          { title: "Company", field: "company_name" },
          { title: "Ref.", field: "reference_no" },
        ]}
        actions={[
          {
            icon: "refresh",
            tooltip: "Refresh",
            isFreeAction: true,
            onClick: event => {
              onManuallyRefresh();
            }
          },
          {
            icon: () => (
              <TextField
                id="address"
                label="last refresh time:"
                value={activeShipmentsState.last_refresh_time}
                margin="filled"
                disabled
              />
            ),
            isFreeAction: true
          }
        ]}
        data={data}
        detailPanel={[
          {
            icon: "visibility",
            tooltip: "View",
            render: rowData => {
              dispatch(stopRefresh());
              return <Shipment {...rowData} />;
            }
          },
          {
            icon: "delete",
            tooltip: "Delete",
            render: rowData => {
              console.log(rowData);
              dispatch(stopRefresh());
              return <Dialog
                open 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Delete shipment"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you would like to delete this shipment?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseAlert} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={()=>dispatch(updateShipment(rowData.id, "remove_shipment", {}))} color="primary" autoFocus>
                    OK
                  </Button>
                </DialogActions>
              </Dialog>     
            }
          },
        ]}
        onRowClick={(event, rowData, togglePanel) =>
          togglePanelAndStopRefresh(togglePanel)
        }
      />
      <Dialog
        open={activeShipmentsState.isError}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlert} color="primary">
            Disagree
          </Button>
          <Button onClick={handleCloseAlert} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
