import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";

import { getShipments } from "../reducers/shipmentsReducer";
import API from "../api/accountsapi";
import Shipment from "./Shipment";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  tableWrapper: {
    maxHeight: 440,
    overflow: "auto"
  }
});

export default function AllShipments() {
  const [isLoading, setisLoading] = React.useState(false);

  const dispatch = useDispatch();
  // const shipmentsState = useSelector(state => state.shipments);
  // if( shipmentsState.isLoading != isLoading)
  //   setisLoading(shipmentsState.isLoading);
  const onManuallyRefresh = ()=>{
    dispatch(getShipments());
  }
  const fetchShipments = async () => {
      dispatch(getShipments());
  };
  // useEffect(() => {
  //   if( shipmentsState.list.length === 0 && !shipmentsState.isLoading)
  //     fetchShipments();
  // }, []);

  // const data = shipmentsState.list.map(shipment => {
  //   return {...shipment, customer: shipment.name2};
  // });

  return (
    <React.Fragment>
      <MaterialTable
        options={{
          grouping: true,
          filtering: true,
        }}
        isLoading={isLoading}
        title="All Shipments"
        columns={[
          { title: "Number", field: "number", grouping: false },
          { title: "Customer", field: "name2", grouping: true },
          { title: "Status", field: "status", grouping: false },
          { title: "createdAt", field: "createdAt", grouping: false },
          { title: "Tracking", field: "tracking_code", grouping: false },
        ]}
        actions={[
          {
            icon: 'refresh',
            tooltip: 'Refresh',
            isFreeAction: true,
            onClick: (event) => {onManuallyRefresh()}
          },
          // {
          //   icon: () => (
          //     <TextField
          //       id="address"
          //       label="last refresh time:"
          //       value={shipmentsState.last_refresh_time}
          //       margin="filled"
          //       disabled
          //     />
          //   ),
          //   isFreeAction: true,
          // }
        ]}        
        data={query=>
          new Promise(async (resolve, reject) => {
          const shipmentsState = await API.getAllShipments(query);
          const data = shipmentsState.list.map(shipment => {
            return {...shipment, customer: shipment.name2};
          });
          setisLoading(false);
        
          resolve({data, page: query.page, totalCount: shipmentsState.count})
        })}
        detailPanel={[
          {
            icon: "visibility",
            tooltip: "Edit account",
            render: rowData => {
              return (
                <Shipment {...rowData} />
              );
            }
          }
        ]}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}        
      />
    </React.Fragment>
  );
}
