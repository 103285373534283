import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { DropzoneDialog, DropzoneArea } from "material-ui-dropzone";
import API from "../api/accountsapi";
import { saveInvoice } from "../reducers/shipmentsReducer";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    }
  },
  paper: {
    width: '100%',
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      width: 'auto'
    }
  },
  input: {
    width: '100%',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
      marginLeft: theme.spacing(1)
    }
  },
  iconButton: {
    padding: theme.spacing(1),
    marginLeft: 'auto'
  }
}));

export default function EditShipmentInvoices(shipment_id, setEditDocuments) {
  const dispatch = useDispatch();

  const [invoices, setInvoices] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openFileUpload, setOpenFileUpload] = useState(false);
  const [fileDescription, setFileDescription] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState({file: [], description: ""});

  const fetchInvoices = async () => {
    const result = await API.getInvoicesForShipment(shipment_id.shipment_id);
    setInvoices(result);
    setOpen(true);
  };

  useEffect(() => {
    if (invoices.length === 0) fetchInvoices();
  }, []);

  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
    dispatch(saveInvoice(shipment_id.shipment_id, fileDescription, uploadedFiles.file));
    shipment_id.setEditInvoices(false);
  };

  const handleFileUpload = files => {
    console.log(fileDescription, files);
    setUploadedFiles({
      file: files[0],
      description: fileDescription
    })
    setOpenFileUpload(false);
  };

  const changeDescription = fileDescription => {
    setFileDescription(fileDescription);
  };

  return (
    <Fragment>
      <Dialog
        className={classes.root}
        open={open}
        fullWidth
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText>
          {invoices.map((invoice, index) => (
            <div className={classes.root} key={index}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Paper component="form" className={classes.paper}>
                    <TextField
                      className={classes.input}
                      label="Description"
                      value={invoice.description}
                      margin="normal"
                      InputProps={{
                        readOnly: true
                      }}
                      variant="filled"
                      fullWidth
                    />
                    <a href={invoice.url} download>
                      <IconButton
                        className={classes.iconButton}
                        aria-label="view"
                        onClick={e => {
                          e.preventDefault();
                          window.open(invoice.url);
                        }}
                      >
                        <CloudDownloadIcon />
                      </IconButton>
                    </a>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          ))}
          <div className={classes.root}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Paper component="form" className={classes.paper}>
                  <TextField
                    className={classes.input}
                    label="Description"
                    value={fileDescription}
                    onChange={e => changeDescription(e.target.value)}
                    margin="normal"
                    variant="filled"
                    fullWidth
                  />
                  <IconButton
                    className={classes.iconButton}
                    aria-label="upload"
                    onClick={e => {
                      e.preventDefault();
                      setOpenFileUpload(true);
                    }}
                  >
                    <CloudUploadIcon />
                  </IconButton>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <DropzoneDialog
        open={openFileUpload}
        filesLimit={1}
        fullWidth
        onSave={handleFileUpload}
        onCanel={() => setOpenFileUpload(false)}
      >
        <DropzoneArea submitButtonText={"text"} />
      </DropzoneDialog>
    </Fragment>
  );
}
