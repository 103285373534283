import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import API from "../api/accountsapi";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: "2px 4px",
    display: "inline-flex",
    alignItems: "center",
    verticalAlign: "top"
  },
  input: {
    marginLeft: theme.spacing(1)
    // flex: 1,
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

export default function EditCustoms(rawData) {
  const [customs, setCustoms] = useState([]);
  const [open, setOpen] = React.useState(false);

  const fetchCustoms = async () => {
    const result = await API.getCustoms(rawData.id);
    setCustoms(result);
    setOpen(true);
  };

  useEffect(() => {
    if (Object(customs).keys.length === 0) fetchCustoms();
  }, []);

  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
    rawData.setCustoms(false);
  };

  return (
    <Dialog
      className={classes.root}
      open={open}
      fullWidth
      maxWidth={"lg"}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
        </DialogContentText>
        <MaterialTable
          options={{
            pageSizeOptions: [5, 10, 20],
            emptyRowsWhenPaging: false,
            paginationType: "stepped"
          }}
          editable={{
            onRowUpdate: (newRow, oldRow) =>
              new Promise(async (resolve, reject) => {
                try {
                  await API.updateShipment(rawData.id, "update_customs",  newRow);
                  resolve();
                } catch (err) {
                  reject(err);
                }
              }),
            onRowDelete: oldRow =>
              new Promise(async (resolve, reject) => {
                try {
                  await API.updatePackage("delete_package", oldRow.id, oldRow);
                  resolve();
                } catch (err) {
                  reject(err);
                }
              })
          }}
          title="Customs"
          columns={[
            { title: "content type", field: "content_type" },
            { title: "terms_of_sale", field: "terms_of_sale" },
            { title: "Description", field: "description" },
            { title: "Value", field: "value" },
            { title: "Currency", field: "currency" },
            { title: "Insurance", field: "insurance" },
            { title: "Insured Value", field: "insurance" },
          ]}
          data={customs}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
