import React, { Fragment, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditPackages from "./EditPackages";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "inline-flex",
    alignItems: "center",
    width: 200,
    verticalAlign: "top"
  },
  input: {
    marginLeft: theme.spacing(1)
    // flex: 1,
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  resize:{
    fontSize:"1rem"
  },

}));

export default function ShowTracking(params) {
  const classes = useStyles();
  const[ tracking_no, setTrackingNo] = useState(params.params.tracking_code);

  const handleTrackingNoChange = event =>{
    event.preventDefault();
    setTrackingNo(event.target.value)
    params.params.callback(event.target.value)
  }

  const showTracking = (tracking_code, carrier)=>{
    if( carrier.includes("DHL"))
      window.open(`https://www.dhl.com/il-en/home/tracking/tracking-express.html?submit=1&tracking-id=${tracking_code}`)  
    if( carrier.includes("TNT"))
      window.open(`https://www.tnt.com/express/en_us/site/tracking.html?searchType=con&cons=${tracking_code}`)
    if( carrier.includes("UPS"))
      window.open(`https://www.ups.com/track?loc=en_US&tracknum=${tracking_code}&requester=WT/trackdetails`);
    if( carrier.toUpperCase().includes("FEDEX"))
      window.open(`https://www.fedex.com/fedextrack/?trknbr=${tracking_code}`);
  }
  return (
    <Fragment>
      <Paper component="form" className={classes.root}>
        <TextField
          id="address"
          label="Tracking"
          className={classes.input}
          value={tracking_no}
          margin="dense"
          InputProps={{
            readOnly: params.params.callback == null ? true: false,
            classes: {
              input: classes.resize,
            }
          }}
          onChange={handleTrackingNoChange}
          variant="standard"
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
          onClick={e => {
            e.preventDefault();
            showTracking(params.params.tracking_code, params.params.carrier);
            console.log("setEditPackages")
          }}
        >
          <VisibilityIcon />
        </IconButton>
      </Paper>
    </Fragment>
  );
}
