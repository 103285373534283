import axios from "axios";
import API from "../api/accountsapi";

const defaultActiveShipments = {
  list: [],
  isLoading: true,
  isError: false,
  refresh: true,
  last_refresh_time: new Date(),
  errors: ""
};

export const getActiveShipments = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(removeActiveShipments());
      const shipments = await API.getActiveShipments();
      dispatch(setActiveShipments(shipments));
    } catch (err) {
      dispatch(setActiveShipmentsError(err.message || err.error.message));
    }
  };
};
export const updateShipment = (shipment_id, action, data) => {
  return async (dispatch, getState) => {
    try {
      await API.updateShipment(shipment_id, action, data);
      if( action == "remove_shipment")
        dispatch(removeShipment(shipment_id));
    } catch (err) {
      dispatch(setActiveShipmentsError(err.response.data.message || err.response.message || err.response));
    }
  };
};
let timer = null, id=null;
export const startRefresh = delay => {
  return async (dispatch, getState) => {
    try {
      clearInterval(timer);

      function tick() {
        dispatch(getActiveShipments())
      }
  
      timer = setInterval(tick, delay);
    } catch (err) {
      dispatch(setActiveShipmentsError(err.message || err.error.message));
    }
  };
};
export const stopRefresh = () => {
  return async (dispatch, getState) => {
    try {
      clearInterval(timer);
    } catch (err) {
      dispatch(setActiveShipmentsError(err.message || err.error.message));
    }
  };
};

export const removeActiveShipments = () => {
  return {
    type: "REMOVE_ACTIVE_SHIPMENTS"
  };
};

export const setActiveShipments = list => {
  return {
    type: "SET_ACTIVE_SHIPMENTS",
    list
  };
};

export const setActiveShipmentsError = data => {
  return {
    type: "ACTIVE_SHIPMENTS_ERROR",
    data
  };
};

export const resetActiveShipmentsError = data => {
  return {
    type: "ACTIVE_SHIPMENTS_RESET_ERROR"
  };
};

const removeShipment = shipment_id=>{
  return {
    type: "ACTIVE_SHIPMENTS_REMOVE_SHIPMENT",
    shipment_id
  }
}
export default (state = defaultActiveShipments, action) => {
  switch (action.type) {
    case "REMOVE_ACTIVE_SHIPMENTS":
      return {
        list: [],
        isLoading: true,
        isError: false,
        errors: ""
      };
    case "SET_ACTIVE_SHIPMENTS": {
      return {
        ...state,
        list: action.list,
        last_refresh_time: new Date(),
        isLoading: false,
        isError: false,
        errors: ""
      };
    }
    case "ACTIVE_SHIPMENTS_ERROR": {
      return {
        ...state,
        isLoading: false,
        isError: true,
        errors: action.data
      };
    }
    case "ACTIVE_SHIPMENTS_RESET_ERROR":{
      return {
        ...state,
        isError: false,
        errors: ""
      }
    }
    case "ACTIVE_SHIPMENTS_REMOVE_SHIPMENT":{
      return {
        ...state,
        list: state.list.filter((shipment)=>shipment.id != action.shipment_id)
      }
    }
    default:
      return state;
  }
};
