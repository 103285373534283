import React, { useEffect, useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import API from "../api/accountsapi";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: "2px 4px",
    display: "inline-flex",
    alignItems: "center",
    verticalAlign: "top"
  },
  input: {
    marginLeft: theme.spacing(1)
    // flex: 1,
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

export default function EditLabels(params) {
  const [labels, setLabels] = useState([]);
  const [open, setOpen] = React.useState(false);

  const fetchLabels = async () => {
    const result = await API.getLabelsForShipment(params.shipment_id);
    setLabels(result);
    setOpen(true);
  };

  useEffect(() => {
    if (labels.length === 0) fetchLabels();
  }, []);

  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
    params.setEditLabels(false);
  };

  const updateRemovedAt = async(label_id, removedAt)=>{
    await API.updateLabel("update_removedAt_label", label_id, removedAt);
  }
  return (
    <Dialog
      className={classes.root}
      open={open}
      fullWidth
      maxWidth={"lg"}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Use Google's location service?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </DialogContentText>
        <MaterialTable
          options={{
            pageSizeOptions: [5, 10, 20],
            emptyRowsWhenPaging: false,
            paginationType: "stepped"
          }}
          editable={{
            onRowUpdate: (newRow, oldRow) =>
              new Promise(async (resolve, reject) => {
                try {
                  await API.updateLabel("update_label", newRow.id, newRow);
                  resolve();
                } catch (err) {
                  reject(err);
                }
              }),
            onRowDelete: oldRow =>
              new Promise(async (resolve, reject) => {
                try {
                  await API.updateLabel("delete_label", oldRow.id, oldRow);
                  resolve();
                } catch (err) {
                  reject(err);
                }
              })
          }}
          title="Labels"
          columns={[
            { title: "Tracking", field: "tracking_code" },
            {
              title: "Label",
              field: "label_url",
              render: rowData => (
                <a target="_blank" href={rowData.label_url}>
                  {rowData.tracking_code}
                </a>
              )
            },
            { title: "Tracker", field: "tracker_id" },
            { title: "Invoice", field: "form_url" },
            { title: "Booking ref.", field: "reference_no" },
            {
              title: "Removed",
              field: "removedAt",
              render: rowData =>
                rowData.removedAt !== null ? (
                  <Checkbox
                    checked={true}
                    onChange={()=>updateRemovedAt(params.shipment_id, {removedAt:false})}
                    // value="Invoiced"
                  />
                ) : (
                  <Checkbox
                    checked={false}
                    onChange={()=>updateRemovedAt(params.shipment_id, {removedAt:true})}
                    // value="Invoiced"
                  />
                )
            }
          ]}
          actions={[
            {
              icon: "location_on",
              tooltip: "tracker",
              onClick: (event, rowData) => 
                new Promise(async (resolve, reject) => {
                  try {
                    await API.updateLabel("create_tracker", rowData.id);
                    resolve();
                  } catch (err) {
                    reject(err);
                  }
                }),
            },]} 
          data={labels}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
