import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ChargeAccount from "./ChargeAccount"

import { updateAccount } from "../reducers/accountsReducer";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    padding: "2px 4px",
    display: "inline-flex",
    alignItems: "center",
    width: 200,
    verticalAlign: "top"
  },
  tableWrapper: {
    maxHeight: 440,
    overflow: "auto"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  menu: {
    width: 200
  },
  buttonField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
    display: "flex"
  },
}));

const paymentOptions = [
  {
    value: true,
    label: "קיים"
  },
  {
    value: false,
    label: "חסר"
  }
];

export default function Account(rowData) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [paymentExists, setPaymentExists] = useState(
    rowData.rowData.payment_exists == true
  );

  // useEffect(() => {
  //   const callUpdateAccount = async () => {
  //     dispatch(updateAccount(rowData.rowData.id, "set_payment_exists", {is_payment_exists: paymentExists}));
  //   };
  //   callUpdateAccount();
  // }, [paymentExists]);

  const [isDirty, setIsDirty] = useState(false);
  const [showChargeAccount, setShowChargeAccount] = useState(false);

  const handleSave = ()=>{
    dispatch(updateAccount(rowData.rowData.id, "set_payment_exists", {is_payment_exists: paymentExists}));
    setIsDirty(false)
  }
  const handleCharge = ()=>{

  }
  const handleChange = event => {
    setPaymentExists(event.target.value);
    setIsDirty(true)
  };

  return (
    <React.Fragment>
    <Container
      style={{
        fontSize: 100,
        textAlign: "left",
        color: "white"
        // backgroundColor: "#E53935"
      }}
    >
      <TextField
        id="zip"
        label="Zip"
        className={classes.textField}
        value={rowData.rowData.zip}
        margin="normal"
      />
      <TextField
        id="state"
        label="City"
        className={classes.textField}
        value={rowData.rowData.city}
        margin="normal"
      />
      <TextField
        id="state"
        label="Address"
        className={classes.textField}
        value={rowData.rowData.address2}
        margin="normal"
      />
      <TextField
        id="address"
        label="tax_number"
        className={classes.textField}
        value={rowData.rowData.tax_number}
        margin="normal"
      />
      <TextField
        id="standard-select-currency"
        select
        label="Payment Exists"
        className={classes.textField}
        value={paymentExists}
        onChange={handleChange}
        SelectProps={{
          MenuProps: {
            className: classes.menu
          }
        }}
        margin="normal"
      >
        {paymentOptions.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="address"
        label="token"
        className={classes.textField}
        value={rowData.rowData.token}
        margin="normal"
      />
      <TextField
        id="address"
        label="expiry"
        className={classes.textField}
        value={rowData.rowData.expiry}
        margin="normal"
      />
      <TextField
        id="address"
        label="Dynamic rate"
        className={classes.textField}
        value={rowData.rowData.dynamic_conversion_rate}
        margin="normal"
      />
      <Button className={classes.buttonField} variant="contained" color="primary" disabled={!isDirty} onClick={(e)=>handleSave()}>
          Save
      </Button>
      <Button className={classes.buttonField} variant="contained" color="primary" onClick={(e)=>setShowChargeAccount(true)}>
          Charge 
      </Button>
    </Container>
    {showChargeAccount && <ChargeAccount account_id={rowData.rowData.id} cancel={()=>setShowChargeAccount(false)}/>}
    </React.Fragment>
  );
}
