import API from "../api/accountsapi";

const defaultShipments = {
  list: [],
  last_refresh_time: new Date(),
  isLoading: true,
  isError: false,
  errors: ""
};

export const getShipments = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(removeShipments());
      const shipments = await API.getAllShipments();
      dispatch(setShipments(shipments));
    } catch (err) {
      dispatch(setShipmentsError(err.message || err.error.message));
    }
  };
};
export const removeShipments = () => {
  return {
    type: "REMOVE_SHIPMENTS"
  };
};

export const setShipments = list => {
  return {
    type: "SET_SHIPMENTS",
    list
  };
};

export const setShipmentsError = data => {
  return {
    type: "SHIPMENTS_ERROR",
    data
  };
};

export const saveDocument = (shipment_id, description, file) => {
  return async (dispatch, getState) => {
    try {
      const documents = await API.saveDocument(shipment_id, description, file);
      dispatch(replaceDocuments(shipment_id, documents));
      console.log(documents);
    } catch (err) {
      dispatch(setShipmentsError(err.message || err.error.message));
    }
  };
};

export const saveInvoice = (shipment_id, description, file) => {
  return async (dispatch, getState) => {
    try {
      const documents = await API.saveDocument(shipment_id, description, file);
      dispatch(replaceDocuments(shipment_id, documents));
      console.log(documents);
    } catch (err) {
      dispatch(setShipmentsError(err.message || err.error.message));
    }
  };
};

export const replaceDocuments = (shipment_id, documents) => {
  return {
    type: "SHIPMENTS_REPLACE_DOCUMENTS",
    shipment_id,
    documents
  };
};
export default (state = defaultShipments, action) => {
  switch (action.type) {
    case "REMOVE_SHIPMENTS":
      return {
        list: [],
        isLoading: true,
        isError: false,
        errors: ""
      };
    case "SET_SHIPMENTS": {
      return {
        ...state,
        list: action.list,
        last_refresh_time: new Date(),
        isLoading: false,
        isError: false,
        errors: ""
      };
    }
    case "SHIPMENTS_ERROR": {
      return {
        ...state,
        isLoading: false,
        isError: true,
        errors: action.data
      };
    }
    case "SHIPMENTS_REPLACE_DOCUMENTS": {
      let shipment = state.list.filter((shipment)=>{
        return shipment.id == action.shipment_id;
      })
      let allOtherShipments = state.list.filter((shipment)=>{
        return shipment.id != action.shipment_id;
      })
      shipment[0].documents = action.documents.length;
      const combinedLists = [...allOtherShipments, ...shipment];
      return {
        ...state,
        list: combinedLists,
      }
    }

    default:
      return state;
  }
};
