import axios from "axios";

const SHIPO_API_URL =
  process.env.REACT_APP_SHIPO_API_URL || "http://localhost:10020";
const TIMEOUT = process.env.REACT_APP_API_TIMEOUT || 360000;

export default {
  getAccounts: async () => {
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
      url: "/getAccounts",
      baseURL: requestURL,
      timeout: TIMEOUT,
      headers: { "x-api-key": "p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2" }
    };
    try {
      const reply = await axios.post(
        requestURL + "/getAccounts",
        {},
        axiosConfig
      );
      return reply.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  getUIDsForAccount: async (account_id)=>{
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
      url: "/getUIDsForAccount",
      baseURL: requestURL,
      timeout: TIMEOUT,
      headers: { "x-api-key": "p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2" }
    };
    try {
      const reply = await axios.post(
        requestURL + "/getUIDsForAccount",
        {account_id},
        axiosConfig
      );
      return reply.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  getActiveShipments: async () => {
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
      url: "/getAccounts",
      baseURL: requestURL,
      timeout: TIMEOUT,
      headers: { "x-api-key": "p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2" }
    };
    try {
      const reply = await axios.post(
        requestURL + "/getActiveShipments",
        {},
        axiosConfig
      );
      return reply.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  getAllShipments: async (query) => {
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
      url: "/getAccounts",
      baseURL: requestURL,
      timeout: TIMEOUT,
      headers: { "x-api-key": "p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2",
    "Content-Type": "application/json" }
    };
    try {
      const reply = await axios.post(
        requestURL + "/getShipments",
        query,
        axiosConfig
      );
      return reply.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  getInvoices: async (carrier_name, invoiced = "Not Invoiced", noReference = false) => {
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
      url: "/getAccounts",
      baseURL: requestURL,
      timeout: TIMEOUT,
      headers: { "x-api-key": "p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2" }
    };
    try {
      const reply = await axios.post(
        requestURL + "/getCarrierInvoices",
        {carrier_name, invoiced, noReference},
        axiosConfig
      );
      return reply.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  getPayments: async (carrier_name, invoiced = "Not Invoiced", noReference = false) => {
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
      url: "/getPayments",
      baseURL: requestURL,
      timeout: TIMEOUT,
      headers: { "x-api-key": "p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2" }
    };
    try {
      const reply = await axios.post(
        requestURL + "/getPayments",
        {carrier_name, invoiced, noReference},
        axiosConfig
      );
      return reply.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  getAddress: async (id) => {
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
      url: "/getAddress",
      baseURL: requestURL,
      timeout: TIMEOUT,
      headers: { "x-api-key": "p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2" }
    };
    try {
      const reply = await axios.post(
        requestURL + "/getAddress",
        { id },
        axiosConfig
      );
      return reply.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  getCustoms: async (id) => {
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
      url: "/getCustoms",
      baseURL: requestURL,
      timeout: TIMEOUT,
      headers: { "x-api-key": "p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2" }
    };
    try {
      const reply = await axios.post(
        requestURL + "/getCustoms",
        { id },
        axiosConfig
      );
      return reply.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  getPackagesForShipment: async shipment_id => {
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
      url: "/getPackagesForShipment",
      baseURL: requestURL,
      timeout: TIMEOUT,
      headers: { "x-api-key": "p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2" }
    };
    try {
      const reply = await axios.post(
        requestURL + "/getPackagesForShipment",
        { shipment_id },
        axiosConfig
      );
      return reply.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  updatePackages: async (action, package_id, package_data) => {
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
      url: "/updatePackage",
      baseURL: requestURL,
      timeout: TIMEOUT,
      headers: { "x-api-key": "p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2" }
    };
    try {
      const reply = await axios.post(
        requestURL + "/updatePackage",
        { package_id, action, package: package_data },
        axiosConfig
      );
      return reply.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  updateAddress: async (action, address_id, address_data) => {
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
      url: "/updateAddress",
      baseURL: requestURL,
      timeout: TIMEOUT,
      headers: { "x-api-key": "p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2" }
    };
    try {
      const reply = await axios.post(
        requestURL + "/updateAddress",
        { ...address_data },
        axiosConfig
      );
      return reply.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  getDocumentsForShipment: async (shipment_id, reference_no) => {
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
      url: "/getPackagesForShipment",
      baseURL: requestURL,
      timeout: TIMEOUT,
      headers: { "x-api-key": "p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2" }
    };
    try {
      const reply = await axios.post(
        requestURL + "/getDocumentsForShipment",
        { shipment_id: shipment_id, reference_no },
        axiosConfig
      );
      return reply.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  getInvoicesForShipment: async shipment_id => {
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
      url: "/getPackagesForShipment",
      baseURL: requestURL,
      timeout: TIMEOUT,
      headers: { "x-api-key": "p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2" }
    };
    try {
      const reply = await axios.post(
        requestURL + "/getInvoicesForShipment",
        { shipment_id },
        axiosConfig
      );
      return reply.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  getLabelsForShipment: async shipment_id => {
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
      url: "/getLabelsForShipment",
      baseURL: requestURL,
      timeout: TIMEOUT,
      headers: { "x-api-key": "p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2" }
    };
    try {
      const reply = await axios.post(
        requestURL + "/getLabelsForShipment",
        { shipment_id },
        axiosConfig
      );
      return reply.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  updateLabel: async (action, label_id, data) => {
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
      url: "/updateLabel",
      baseURL: requestURL,
      timeout: TIMEOUT,
      headers: { "x-api-key": "p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2" }
    };
    try {
      const reply = await axios.post(
        requestURL + "/updateLabel",
        { action, label_id, data },
        axiosConfig
      );
      return reply.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  updateAccount: async (account_id, action, data) => {
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
      url: "/updateAccount",
      baseURL: requestURL,
      timeout: TIMEOUT,
      headers: { "x-api-key": "p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2" }
    };
    try {
      const reply = await axios.post(
        requestURL + "/updateAccount",
        { account_id, action, data: { ...data } },
        axiosConfig
      );
      return reply.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  updateShipment: async (shipment_id, action, data) => {
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
      url: "/updateShipment",
      baseURL: requestURL,
      timeout: TIMEOUT,
      headers: { "x-api-key": "p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2" }
    };
    try {
      const reply = await axios.post(
        requestURL + "/updateShipment",
        { shipment_id, action, data: { ...data } },
        axiosConfig
      );
      return reply.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  updateInvoice: async (shipment_id, action, data) => {
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
      url: "/updateShipment",
      baseURL: requestURL,
      timeout: TIMEOUT,
      headers: { "x-api-key": "p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2" }
    };
    try {
      const reply = await axios.post(
        requestURL + "/updateInvoice",
        { shipment_id, action, data: { ...data } },
        axiosConfig
      );
      return reply.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  chargeAccount: async (account_id, amount) => {
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
      url: "/chargeAccount",
      baseURL: requestURL,
      timeout: TIMEOUT,
      headers: { "x-api-key": "p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2" }
    };
    try {
      const reply = await axios.post(
        requestURL + "/chargeAccount",
        { account_id, amount},
        axiosConfig
      );
      return reply.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  saveDocument : async (shipment, description, document)=>{
    const requestURL = SHIPO_API_URL;
    let axiosConfig = {
        url : "/saveDocument ",
    baseURL: requestURL,
    timeout: TIMEOUT,
    headers: {'x-api-key': 'p4Mk3kEJwA5ZMXVxwNCRDUy3KKvdV2t4vSiU39Y2', 'Content-Type': 'multipart/form-data'}
    };
    // when changing any formData parameters, you must edit also the swagger function.
    const formData = new FormData();
    formData.append('file',document)
    formData.append('fileName',`${document.name}`)
    formData.append('shipment',shipment)
    formData.append('editable',1)
    formData.append('description',description)
    try{
      const result = await axios.post(requestURL + "/saveDocument", formData , axiosConfig);
      return result.data;
    }
    catch(err){
      console.log(err);
      throw err;
    }
  },
};
