import axios from "axios";
import API from "../api/accountsapi";
import { bigIntLiteral } from "../../../../../AppData/Local/Microsoft/TypeScript/3.6/node_modules/@babel/types/lib";

const defaultLabel = {
  list: [],
  uids: [],
  isLoading: true,
  isError: false,
  errors: ""
};

export const getAccounts = () => {
  console.log("getAccounts");
  return async (dispatch, getState) => {
    try {
      dispatch(removeAccountsList());
      const accounts = await API.getAccounts();
      dispatch(setAccounts(accounts));
    } catch (err) {
      dispatch(setAccountsError(err.message || err.error.message));
    }
  };
};
export const updateAccount = (account_id, action, data) => {
  console.log("updateAccount");
  return async (dispatch, getState) => {
    try {
      const accounts = await API.updateAccount(account_id, action, data);
      dispatch(setUpdateAccount(account_id, action, data));
    } catch (err) {
      dispatch(setAccountsError(err.message || err.error.message));
    }
  };
};
export const getUids = (account) => {
  return async (dispatch, getState) => {
    try {
      dispatch(removeUIDSList());
      const uids = await API.getUIDsForAccount(account);
      dispatch(setUids(uids));
    } catch (err) {
      dispatch(setAccountsError(err.message || err.error.message));
    }
  };
};
export const setAccounts = accounts => {
  return {
    type: "SET_ACCOUNTS_LIST",
    accounts
  };
};
export const setUids = uids=>{
  return {
    type: "SET_UIDS",
    uids
  }
}
export const setAccountsError = data => {
  return {
    type: "ACCOUNTS_ERROR",
    data
  };
};
export const clearAccountsError = () => {
  return {
    type: "CLEAR_ACCOUNTS_ERROR"
  };
};
export const removeAccountsList = () => {
  return {
    type: "REMOVE_ACCOUNTS_LIST"
  };
};
export const removeUIDSList = ()=>{
  return {
    type: "REMOVE_UIDS"
  }
}
export const setUpdateAccount = (account_id, action, data) => {
  return {
    type: "UPDATE_ACCOUNT_DATA",
    account_id,
    action,
    data
  };
};
export const chargeAccount = (account_id, amount) => {
  return async (dispatch, getState) => {
    try {
      await API.chargeAccount(account_id, amount);
    } catch (err) {
      dispatch(setAccountsError(err.message || err.error.message));
    }
  };
};
export default (state = defaultLabel, action) => {
  switch (action.type) {
    case "REMOVE_ACCOUNTS_LIST":
      return defaultLabel;
    case "SET_ACCOUNTS_LIST": {
      return {
        ...state,
        list: action.accounts,
        uids: [],
        isLoading: false,
        isError: false,
        errors: ""
      };
    }
    case "SET_UIDS":{
      return {
        ...state,
        uids: action.uids,
      }
    }
    case "UPDATE_ACCOUNT_DATA": {
      return {
        list: state.list.map(account => {
          if (account.id !== action.account_id) return { ...account };
          else {
            switch (action.action) {
              case "set_payment_exists":
                return {
                  ...account,
                  payment_exists: action.data.is_payment_exists
                };
              default:
                return { ...account };
            }
          }
        }),
        isLoading: false,
        isError: false,
        errors: ""
      };
    }
    case "ACCOUNTS_ERROR": {
      return {
        ...state,
        isLoading: false,
        isError: true,
        errors: action.data
      };
    }
    case "CLEAR_ACCOUNTS_ERROR": {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: ""
      };
    }
    case "REMOVE_UIDS":{
      return {
        ...state,
        uids: [],
      }
    }
    default:
      return state;
  }
};
